import BaseApi from '@/_types/api/base.api.class';
import { TUser } from '@/_types/user.type';
import { TUserConfig } from '@/_types/user-config.type';
import {AxiosRequestConfig, AxiosResponse, CancelToken} from 'axios';
import UserHelper from '@/_helpers/user.helper';

export type TGetUserInfoParams = {
  id?: number;
  company_id?: number;
}

export class UserApi extends BaseApi {

  public async getUserInfo(params: TGetUserInfoParams, cancelToken?: CancelToken): Promise<TUser> {
    const config: AxiosRequestConfig = {
      url: '/user/info',
      method: 'GET',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<any>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return UserHelper.responseToUserInfoConverter(response.data);
  }

  public async patchUserConfig(params: TUserConfig, cancelToken?: CancelToken): Promise<boolean> {
    const config: AxiosRequestConfig = {
      url: '/user/config',
      method: 'PATCH',
      params: params,
      cancelToken,
    };

    let response: AxiosResponse;
    let result = false;
    try {
      response = await this.axios.request<any>(config);
      result = response && response.status === 202;
    } catch (error) {
      throw this.responseError(error);
    }

    return result;
  }

}

const userApi = new UserApi();
export default userApi;
