


import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator';
import {TEvent} from '@/_types/event.type';
import {TPromoPage} from '@/_types/promo-page/promo-page.type';
import {TUser} from '@/_types/user.type';
import {TContact} from '@/_types/contact.type';
import {DateTimeFormat} from '@/_types/date-time-format.enum';
import LanguagePicker from '@/_components/language-picker/language-picker.vue';
import EwAvatar from '@/_modules/standalone-company/components/UI/ew-avatar/ew-avatar.vue';
import EwButton from '@/_modules/standalone-company/components/UI/Ew-Button/Ew-Button.vue';
import {Action, Getter} from 'vuex-class';
import {TabNames} from '@/_modules/promo/types/side-bar-right-store-state.type';
import momentTimezone from 'moment-timezone';
import MobileAppHint from '@/_components/mobile-app-hint/mobile-app-hint.vue';

const ENVIRONMENT_NAME: string = process.env.VUE_APP_ENV;

@Component({
  components: {
    LanguagePicker,
    EwAvatar,
    EwButton,
    MobileAppHint
  }
})
export default class BrandingHeader extends Vue {

  @Ref('global-dropdown-menu') globalDropdownMenu!: HTMLElement;
  @Ref('langPickerMob') langPickerMobile: HTMLElement;
  @Getter('authStore/isAuthenticated') isAuthenticated: boolean;
  @Getter('sideBarRightStore/isOpen') isRightSideBarOpen: boolean;
  @Getter('sideBarRightStore/isSideBarRightComponentPresent') isRightSideBarPresent: boolean;
  @Getter('sideBarRightStore/activeTabName') rightSideBarActiveTab: TabNames;
  @Action('authStore/setStandaloneAuthVisibility') setStandaloneAuthVisibility: (params: boolean) => Promise<any>;
  @Action('sideBarRightStore/open') openRightSideBar: () => void;
  @Action('sideBarRightStore/close') closeRightSideBar: () => void;
  @Action('sideBarRightStore/setActiveTab') setRightSideBarTab: (tabName: TabNames) => void;

  public isUserDropdownMenuVisible: boolean = false;
  public isGlobalDropdownVisible: boolean = false;
  public isTimezoneHintVisible: boolean = false;

  @Prop()
  public readonly event: TEvent;

  @Prop()
  public readonly company: TPromoPage;

  @Prop()
  public readonly user: TUser;

  @Prop()
  public readonly myself: TContact;

  @Watch('isGlobalDropdownVisible')
  public onShowDropdownChanged(): void {
    if (this.isGlobalDropdownVisible) {
      this.globalDropdownMenu.style.maxHeight = '100vh';
      window.setTimeout(this.overrideOverflowStyle, 400);
    } else {
      this.globalDropdownMenu.style.maxHeight = '';
      this.doNotOverrideOverflowStyle();
    }
  }

  public get eventNameHrefUrl(): string {
    let domain = 'app.eventswallet.com';
    if (ENVIRONMENT_NAME === 'development' || ENVIRONMENT_NAME === 'dev') {
      domain = window.location.host;
    }
    if (this.event && this.event.id && this.$route.name !== 'standalone-company') {
      return 'https://' + domain + '/' + this.$i18n.locale + '/events/' + this.event.id.toFixed(0) + '/';
    }
    return 'https://eventswallet.com/';
  }

  public isDropdownOverflowOverridden: boolean = false;

  public overrideOverflowStyle(): void {
    this.isDropdownOverflowOverridden = true;
  }

  public doNotOverrideOverflowStyle(): void {
    this.isDropdownOverflowOverridden = false;
  }

  public get myAvatarUrl(): string {
    return this.myself && this.myself.photo_url;
  }

  public get myFullName(): string {
    return this.myContactFullName || this.myUserFullName || '';
  }

  public get myContactFullName(): string {
    return (this.myself && this.myself.fullName) || '';
  }

  public get myUserFullName(): string {
    if (!this.user) {
      return '';
    }

    return ([
      this.user.first_name,
      this.user.last_name,
    ]
      .filter(x => x)
      .join(' ')
      .trim()
    ) || this.user.email || '';
  }

  public get myFullCompany(): string {
    return this.myContactFullCompany || this.myUserFullCompany || '';
  }

  public get myContactFullCompany(): string {
    return (this.myself && this.myself.fullCompany);
  }

  public get myUserFullCompany(): string {
    if (!this.user) {
      return '';
    }

    return [
      this.user.company_name,
      this.user.company_position,
    ].filter(x => x)
      .join(', ')
      .replace(/\s{2,}/, ' ');
  }

  public get eventLogo(): string {
    return this.event && this.event.org_logo;
  }

  public get eventName(): string {
    return this.event && this.event.title;
  }

  public get eventLocation(): string {
    const cityName: string = this.event && this.event.city ? this.event.city.name : '';
    const countryName: string = this.event && this.event.country ? this.event.country.name : '';

    return [cityName, countryName].filter(x => x).join(', ');
  }

  public get eventDateStart(): Date {
    return this.event && this.event.date_start;
  }

  public get eventDateEnd(): Date {
    return this.event && this.event.date_end;
  }

  public get eventDateStartStr(): string {
    if (!this.event) {
      return '';
    }

    if ((this.eventDateStart && this.eventDateEnd)
      && (this.eventDateStart.getFullYear() === this.eventDateEnd.getFullYear())
    ) {
      return this.$moment(this.eventDateStart).format(DateTimeFormat.DAY_MONTH_TIME);
    } else {
      return this.$moment(this.eventDateStart).format(DateTimeFormat.DAY_MONTH_TIME_YEAR);
    }
  }

  public get isEndDateNeeded(): boolean {
    return this.$moment(this.eventDateStart).format('LL') !== this.$moment(this.eventDateEnd).format('LL');
  }

  public get eventDateEndStr(): string {
    if (!this.event) {
      return '';
    }
    return this.$moment(this.eventDateEnd).format(DateTimeFormat.DAY_MONTH_TIME_YEAR);
  }

  public async logout(): Promise<void> {
    await this.$store.dispatch('authStore/authLogout');
    window.location.reload();
  }

  private onContentUserClick(): void {
    if (this.isAuthenticated && this.isRightSideBarPresent) {
      this.manageRightSideBar();
      return;
    }

    if (this.isAuthenticated) {
      this.isUserDropdownMenuVisible = !this.isUserDropdownMenuVisible;
    } else {
      if (['standalone-company', 'standalone-contact'].indexOf(this.$route.name) < 0) {
        this.$router.push({
          name: 'auth-page',
        }).catch(/* ignore */);
        this.isGlobalDropdownVisible = false;
        return;
      }
      this.setStandaloneAuthVisibility(true);
      this.isGlobalDropdownVisible = false;
    }
  }

  public manageRightSideBar(): void {
    if (!this.isRightSideBarOpen) {
      this.setRightSideBarTab(TabNames.CONTACT);
      this.openRightSideBar();
      return;
    }

    if (this.isRightSideBarOpen && this.rightSideBarActiveTab !== TabNames.CONTACT) {
      this.setRightSideBarTab(TabNames.CONTACT);
      return;
    }

    if (this.rightSideBarActiveTab === TabNames.CONTACT) {
      this.closeRightSideBar();
    }
  }

  public showTimezoneHint(): void {
    this.isTimezoneHintVisible = true;
  }

  public hideTimezoneHint(): void {
    this.isTimezoneHintVisible = false;
  }

  public getDateFormattedForPopover(date: string): string {
    return momentTimezone.utc(date).tz(this.event.time_region).format('DD MMM YYYY HH:mm');
  }

  public onCloseDropdownContactClick(): void {
    this.isUserDropdownMenuVisible = false;
  }

}
