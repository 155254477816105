import { AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import BaseApi from '@/_types/api/base.api.class';
import {
  TCreatePromoPage,
  TPromoPage,
  TCodeList,
} from '@/_types/promo-page/promo-page.type';
import { TLivePage, TLivePageResponse, TSponsor } from '@/_types/promo-page/live-page.type';
import { TApiListResponse } from '@/_types/api/api-list-response.type';

export type TGetContactPromoPageParams = {
  eventId: number;
  contactId: number;
}

export type TCreatePromoPageParams = {
  eventId: number;
  promoPageData: TCreatePromoPage;
}

export type TDeletePromoPageParams = {
  eventId: number;
  externalId: string;
}

export type TDeletePromoPageCodeParams = {
  eventId: number;
  externalId: string;
  codeId: number;
}

export type TGetAllEventPromoPagesParams = {
  eventId: number;
  limit: number;
  offset: number;
  title?: string;
  pavilion_id?: number;
  country_id?: number;
  tags?: number[];
};

export type TGetMyEventPromoPagesParams = {
  eventId: number;
  limit: number;
  offset: number;
  title?: string;
  pavilion_id?: number;
  tags?: number[];
};

export type TPromopageCodeListParams = {
  eventId: number;
};

export type TGetLivePageParams = {
  eventId: number;
}

export type TPatchLivePageParams = {
  eventId: number;
  livePageData: TLivePage;
}

export type TApiSponsors = {
  eventId: number;
  sponsor: TSponsor;
}

export type TLivePageAgendaItemResponse = {
  title: string;
}

export type TLivePageAgendaItem = {
  isEditing: boolean;
  toDelete: boolean;
  data: TLivePageAgendaItemResponse;
}

export type TPromoPageFavoriteParams = {
  event_id: string;
  external_id: string;
}

export class PromoPageApi extends BaseApi {

  public async getContactPromoPage(params: TGetContactPromoPageParams, cancelToken?: CancelToken): Promise<TPromoPage> {
    const { eventId, contactId } = params;
    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/contact/${contactId}/promopage`,
      method: 'GET',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TPromoPage>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  }

  public async createPromoPage(params: TCreatePromoPageParams, cancelToken?: CancelToken): Promise<TPromoPage> {
    const { eventId, promoPageData } = params;

    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/promopage/create`,
      method: 'POST',
      params: promoPageData,
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TPromoPage>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  }

  public async promoPageCodeList(params: TPromopageCodeListParams, cancelToken?: CancelToken): Promise<TApiListResponse<TCodeList[]>> {
    const { eventId } = params;

    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/promopage_code/list`,
      method: 'GET',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TApiListResponse<TCodeList[]>>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  }

  public async deletePromoPage(params: TDeletePromoPageParams, cancelToken?: CancelToken): Promise<AxiosResponse> {
    const { eventId, externalId } = params;

    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/promopage/${externalId}`,
      method: 'DELETE',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<void>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response;
  }

  public async getPromoPage(params: TDeletePromoPageParams, cancelToken?: CancelToken): Promise<TPromoPage> {
    const { eventId, externalId } = params;

    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/promopage/${externalId}`,
      method: 'GET',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TPromoPage>(config);
      return response.data;
    } catch (error) {
      throw this.responseError(error);
    }
  }

  public async deletePromoPageCode(params: TDeletePromoPageCodeParams, cancelToken?: CancelToken): Promise<void> {
    const { eventId, externalId, codeId } = params;

    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/promopage/${externalId}/code/${codeId}`,
      method: 'DELETE',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<void>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  }

  public async getAllEventPromoPages(params: TGetAllEventPromoPagesParams, cancelToken?: CancelToken): Promise<TPromoPage[]> {
    const { eventId, limit, offset, title, pavilion_id, tags, country_id } = params;

    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/promopage/list/all`,
      method: 'GET',
      cancelToken,
    };

    config.params = {};

    if (tags.length > 0) {
      config.params = {
        tags: [...tags]
      };
    }

    if (limit || offset) {
      if (limit) {
        config.params.limit = limit;
      }
      if (offset) {
        config.params.offset = offset;
      }
    }

    if (title) {
      config.params.title = title;
    }

    if (country_id) {
      config.params.country_id = country_id;
    }

    if (pavilion_id) {
      config.params.pavilion_id = pavilion_id;
    }

    const { data } = await this.axios.request(config);
    return data;
  }

  public async getMyEventPromoPages(params: TGetMyEventPromoPagesParams, cancelToken?: CancelToken): Promise<TPromoPage[]> {
    const { eventId, limit, offset } = params;

    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/promopage/list`,
      method: 'GET',
      cancelToken,
    };

    config.params = {};

    if (limit || offset) {
      if (limit) {
        config.params.limit = limit;
      }
      if (offset) {
        config.params.offset = offset;
      }
    }

    const { data } = await this.axios.request(config);
    return data;
  }

  public async getLivePage(params: TGetLivePageParams, cancelToken?: CancelToken): Promise<TLivePage> {
    const { eventId } = params;
    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/live`,
      method: 'GET',
      cancelToken,
    };

    let response;
    const mappedResponseData: TLivePage = {
      title: '',
      descr: '',
    };
    try {
      response = await this.axios.request<TLivePageResponse>(config);
      // Adapt response type to TLivePage. This code is good
      if (response && response.data) {
        mappedResponseData.title = response.data.title || '';
        mappedResponseData.descr = response.data.descr || '';
        mappedResponseData.video_player_frame = response.data.video_player_frame || '';
        mappedResponseData.video_file_url = response.data.video_file_url || '';
        mappedResponseData.agenda = (response.data.agenda && response.data.agenda.length) ? response.data.agenda.map((x: TLivePageAgendaItemResponse): TLivePageAgendaItem => {
          return {
            isEditing: false,
            toDelete: false,
            data: {
              ...x
            }
          };
        }) : [];
        mappedResponseData.agenda_video_player_frame = response.data.agenda_video_player_frame || '';
        mappedResponseData.agenda_video_file_url = response.data.agenda_video_file_url || '';
        mappedResponseData.photos = response.data.photos || [];
        mappedResponseData.show_title = response.data.show_title;
        mappedResponseData.show_event_program = response.data.show_event_program;
        mappedResponseData.show_sponsors = response.data.show_sponsors;
        mappedResponseData.show_event_news = response.data.show_event_news;
        mappedResponseData.show_event_announcements = response.data.show_event_announcements;
        mappedResponseData.show_streaming = response.data.show_streaming;
        mappedResponseData.use_program_sessions = response.data.use_program_sessions;
        mappedResponseData.agendas = response.data.agendas;
        mappedResponseData.sponsors = response.data.sponsors;

      }
    } catch (error) {
      throw this.responseError(error);
    }
    return mappedResponseData;
  }

  public async patchLivePage(params: TPatchLivePageParams, cancelToken?: CancelToken): Promise<number> {
    const { eventId, livePageData } = params;
    // TODO: remove me when GET agenda is the same as PATCH agenda
    // This adapts the agenda for the GET request
    // Hardcode for the win.
    const livePageDataGetRequest: TLivePage = {
      title: livePageData.title,
      descr: livePageData.descr,
      video_player_frame: livePageData.video_player_frame,
      video_file_url: livePageData.video_file_url,
      agenda_video_player_frame: livePageData.agenda_video_player_frame,
      agenda_video_file_url: livePageData.agenda_video_file_url,
      photos: livePageData.photos,
      agendas: livePageData.agendas,
      show_streaming: livePageData.show_streaming,
      show_title: livePageData.show_title,
      show_event_program: livePageData.show_event_program,
      show_sponsors: livePageData.show_sponsors,
      show_event_news: livePageData.show_event_news,
      show_event_announcements: livePageData.show_event_announcements,
      use_program_sessions: livePageData.use_program_sessions
    };
    // Adaptation ends.
    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/live`,
      method: 'PATCH',
      params: livePageDataGetRequest,
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<AxiosResponse>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.status;
  }

  public async putSponsor(params: TApiSponsors, cancelToken?: CancelToken): Promise<number> {
    const { eventId, sponsor } = params;
    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/live/sponsor`,
      method: 'POST',
      params: sponsor,
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<AxiosResponse>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.status;
  }

  public async deleteSponsor(params: TApiSponsors, cancelToken?: CancelToken): Promise<number> {
    const { eventId, sponsor } = params;
    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/live/sponsor/${sponsor}`,
      method: 'DELETE',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<AxiosResponse>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.status;
  }

  public async addFavorite(params: TPromoPageFavoriteParams, cancelToken?: CancelToken): Promise<any> {
    const { event_id, external_id } = params;
    const config: AxiosRequestConfig = {
      url: `/event/${event_id}/promopage/${external_id}/fav`,
      method: 'PUT',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<void>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response;
  }

  public async removeFavorite(params: TPromoPageFavoriteParams, cancelToken?: CancelToken): Promise<any> {
    const { event_id, external_id } = params;
    const config: AxiosRequestConfig = {
      url: `/event/${event_id}/promopage/${external_id}/fav`,
      method: 'DELETE',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<void>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response;
  }
}

const promoPageApi = new PromoPageApi();
export default promoPageApi;
