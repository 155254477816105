


import { Component, Vue } from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import LanguagePicker from '@/_components/language-picker/language-picker.vue';

@Component({
  components: {
    LanguagePicker,
  },
})
export default class Navigation extends Vue {

  @Getter('authStore/isAuthenticated') isAuthenticated: boolean;
  @Getter('authStore/isLoading') isAuthLoading: boolean;
  @Action('authStore/authLogout') authLogout: () => Promise<void>;

  public get isCreateEventButtonVisible(): boolean {
    return this.isAuthenticated && this.$route.name !== 'event-info' && this.$route.name !== 'event-create';
  }

  public get isHeaderLogoVisible(): boolean {
    return this.$route.meta ? !!this.$route.meta.showHeaderLogo : false;
  }

  public get isHomeSiteIntroVisible(): boolean {
    return this.$route.meta ? !!this.$route.meta.showHomeSiteIntro : false;
  }

  /* Home URL is / + current locale */
  public get homeURL(): string {
    return '/' + (this.$i18n.locale === this.$i18n.fallbackLocale ? '' : this.$i18n.locale);
  }

  public async onLogoutClick(): Promise<void> {
    await this.authLogout();
    this.$router.push({ name: 'home' });
  }

  public onCreateEventButtonClick(): void {
    this.$router.push({ name: 'event-create' });
  }
}
