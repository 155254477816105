import { ActionContext, Module } from 'vuex';

import { TAppStoreState } from '@/_types/store/app-store-state.type';
import AxiosCancellableRequest from '@/_types/api/axios-cancellable-request.class';
import notesApi, {TGetNotesParams, TRequestNoteParams} from '@/_api/notes/notes.api';
import {TApiListResponse} from '@/_types/api/api-list-response.type';
import {TNote} from '@/_types/store/note.type';
import {TStoreEntityState} from '@/_types/store/store-entity-state.type';
import {AxiosResponse} from 'axios';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';

const getNotesRequest = new AxiosCancellableRequest<TGetNotesParams, TApiListResponse<TNote>>(notesApi.getNotes.bind(notesApi));
const getNotesMyRequest = new AxiosCancellableRequest<TGetNotesParams, TApiListResponse<TNote>>(notesApi.getNotesMy.bind(notesApi));
const addNote = new AxiosCancellableRequest<TRequestNoteParams, TApiListResponse<TNote>>(notesApi.addNote.bind(notesApi));
const editNote = new AxiosCancellableRequest<TRequestNoteParams, TApiListResponse<TNote>>(notesApi.editNote.bind(notesApi));
const removeNote = new AxiosCancellableRequest<TGetNotesParams, AxiosResponse<TNote>>(notesApi.removeNote.bind(notesApi));
const addFav = new AxiosCancellableRequest<number, AxiosResponse>(notesApi.addFav.bind(notesApi));
const removeFav = new AxiosCancellableRequest<number, AxiosResponse>(notesApi.removeFav.bind(notesApi));

export type TNoteStoreState = {
  eventId: number;
  noteEntity: TStoreEntityState<TNote>;
}

const notesStore: Module<TNoteStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    eventId: null,
    noteEntity: {
      data: null,
      isLoading: false,
      error: null,
    },
  },
  getters: {
    eventId: (state: TNoteStoreState): number => {
      return state.eventId;
    },
    isNoteLoading: (state: TNoteStoreState): boolean => {
      return state.noteEntity.isLoading;
    },
    noteList: (state: TNoteStoreState): TNote => {
      return state.noteEntity.data;
    },
  },
  actions: {
    notesAll: async (context: ActionContext<TNoteStoreState, TAppStoreState>, params): Promise<TApiListResponse<TNote>> => {
      const { commit, state } = context;
      const {eventId} = params;
      if (state.eventId !== eventId) {
        commit('setEventId', eventId);
      }

      let data;

      try {
        data = await getNotesRequest.load(params);
        commit('setNoteList', data);
        return data;
      } catch (error) {
        commit('notesError', error);
        return null;
      } finally {
        commit('setNoteList', data);
      }
    },

    notesMy: async (context: ActionContext<TNoteStoreState, TAppStoreState>, params): Promise<TApiListResponse<TNote>> => {
      const { commit, state } = context;
      const {eventId} = params;
      if (state.eventId !== eventId) {
        commit('setEventId', eventId);
      }

      let data;

      try {
        data = await getNotesMyRequest.load({ eventId });
        commit('setNoteList', data);
        return data;
      } catch (error) {
        commit('notesError', error);
        return null;
      } finally {
        commit('setNoteList', data);
      }
    },

    addNote: async (context: ActionContext<TNoteStoreState, TAppStoreState>, params): Promise<TApiListResponse<TNote>> => {
      const { commit } = context;

      let data;

      try {
        data = await addNote.load(params);
        commit('setNoteList', data);
        return data;
      } catch (error) {
        commit('notesError', error);
        return null;
      } finally {
        commit('setNoteList', data);
      }
    },
    editNote: async (context: ActionContext<TNoteStoreState, TAppStoreState>, params): Promise<TApiListResponse<TNote>> => {
      const { commit } = context;

      let data;

      try {
        data = await editNote.load(params);
        commit('setNoteList', data);
        return data;
      } catch (error) {
        commit('notesError', error);
        return null;
      } finally {
        commit('setNoteList', data);
      }
    },
    removeNote: async (context: ActionContext<TNoteStoreState, TAppStoreState>, params): Promise<AxiosResponse<TNote>> => {
      const { commit } = context;
      let data;

      try {
        data = await removeNote.load(params);
        return data;
      } catch (error) {
        commit('notesError', error);
        return null;
      }
    },
    addFav: async (context: ActionContext<TNoteStoreState, TAppStoreState>, params): Promise<AxiosResponse> => {
      const { commit } = context;
      let data;

      try {
        data = await addFav.load(params);
        return data;
      } catch (error) {
        commit('notesError', error);
        return null;
      }
    },
    removeFav: async (context: ActionContext<TNoteStoreState, TAppStoreState>, params): Promise<AxiosResponse> => {
      const { commit } = context;
      let data;

      try {
        data = await removeFav.load(params);
        return data;
      } catch (error) {
        commit('notesError', error);
        return null;
      }
    },
  },
  mutations: {

    setEventId(state: TNoteStoreState, eventId: number): void {
      if (state.eventId === eventId) {
        return;
      }

      state.eventId = eventId || null;
      state.noteEntity.data = null;
      state.noteEntity.isLoading = false;
      state.noteEntity.error = null;
    },

    setNoteList(state: TNoteStoreState, noteList: TNote): void {
      state.noteEntity.data = noteList || null;
      state.noteEntity.isLoading = false;
    },

    noteListError(state: TNoteStoreState, error: ApiErrorResponseData): void {
      state.noteEntity.error = error;
    },
  },
};

export default notesStore;
