


import { Component, Vue, Prop } from 'vue-property-decorator';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import EwClosePopUpIcon from '@/_modules/icons/components/ew-close-pop-up-icon.vue';

@Component({
  components: {
    EwClosePopUpIcon,
  },
})
export default class EwPopUp extends Vue {

  @Prop({type: String, default: ''})
  public readonly title: string;

  @Prop({ type: Boolean, default: false })
  public readonly hideCloseButton: boolean;

  @Prop({ type: String })
  public readonly widthType: string;

  private destroyed$: Subject<void> = new Subject<void>();

  public mounted(): void {
    this.subscribeToPageEvents();
  }

  public beforeDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public onCloseClick(): void {
    this.$emit('close');
  }

  private subscribeToPageEvents(): void {
    fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(this.onDocumentKeyDown);
  }

  private onDocumentKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.$emit('close');
    }
  }
}
