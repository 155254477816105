import { ActionContext, Module } from 'vuex';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import { TEwSharerStoreState } from '@/_types/store/ew-sharer-store-state.type';

export type TOpenEwSharerPayload = {
  eventTarget: Element;
  url?: string;
}

const ewSharerStore: Module<TEwSharerStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    isSharerVisible: false,
    urlToShare: '',
    shareEventTarget: null,
  },
  getters: {
    isSharerVisible: (state: TEwSharerStoreState): boolean => state.isSharerVisible,
    shareEventTarget: (state: TEwSharerStoreState): EventTarget => state.shareEventTarget,
    urlToShare: (state: TEwSharerStoreState): string => state.urlToShare,
  },
  mutations: {
    setUrlToShare(state: TEwSharerStoreState, url: string): void {
      state.urlToShare = url;
    },
    setEventTarget(state: TEwSharerStoreState, eventTarget: Element): void {
      state.shareEventTarget = eventTarget;
    },
    clearUrlToShare(state: TEwSharerStoreState): void {
      state.urlToShare = '';
    },
    open(state: TEwSharerStoreState): void {
      state.isSharerVisible = true;
    },
    close(state: TEwSharerStoreState): void {
      state.isSharerVisible = false;
    }
  },
  actions: {
    openSharer: ({commit, dispatch}: ActionContext<TEwSharerStoreState, TAppStoreState>, payload: TOpenEwSharerPayload): void => {

      let { eventTarget, url } = payload;

      if (!eventTarget) {
        dispatch('closeSharer');
        return;
      }

      // TODO: recursion if eventTarget is an SVG. Research the reason and remove this if. — reason is inside the component, waiting until w and h > 0
      if (eventTarget.tagName.toLowerCase() === 'svg') {
        eventTarget = eventTarget.parentNode as Element;
      }

      commit('setEventTarget', eventTarget);

      if (!url) {
        const defaultUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + window.location.search + window.location.hash;
        commit('setUrlToShare', defaultUrl);
      } else {
        commit('setUrlToShare', url);
      }

      commit('open');
    },
    closeSharer: ({commit}: ActionContext<TEwSharerStoreState, TAppStoreState>): void => {
      commit('clearUrlToShare');
      commit('setEventTarget', null);
      commit('close');
    },

  },
};

export default ewSharerStore;
