import api from '../../api';
// TODO: remove this whole file? UNUSED
export default {
  strict: true,
  namespaced: true,
  state: {
    ticketsList: [],
    ticketInfo: [],
    createTicketError: ''
  },
  getters: {
  },
  mutations: {
    setTicketsList(state, data) {
      state.ticketsList = data;
    },
    setTicket(state, data) {
      state.ticketInfo = data;
    },
    deleteTicket() {
    },
    createTicketError(state, data) {
      state.createTicketError = data
    }
  },
  actions: {
    createTicket: async function ({commit, rootState}, data) {
      let token = rootState.authStore.token;
      await api.createTicket.createTicket({token, data})
        .then((resolve) => {
          commit('setTicket', resolve)
        }).catch((e) => {
          commit('createTicketError', e.data);
        })
    },
    callTicketsList: async function ({commit}, data) {
      await api.ticketsList.ticketsList(data)
        .then((resolve) => {
          commit('setTicketsList', resolve);
        }).catch((e) => {
          commit('createTicketError', e.data);
        });
    },
    viewTicket: async function ({commit, rootState}, id) {
      let token = rootState.authStore.token;
      await api.ticketsList.viewTicket({token, id})
        .then((resolve) => {
          commit('setTicket', resolve)
        }).catch((e) => {
          commit('createTicketError', e.data);
        });
    },
    deleteTicket: async function ({commit, rootState}, id){
      let token = rootState.authStore.token;
      await api.deleteTicket.deleteTicket({token, id})
        .then((resolve) => {
          commit('deleteTicket', resolve);
        }).catch((e) => {
          // TODO: обработка ошибки удаления билета
          //commit('deleteTicketError', e.data);
        });
    }
    // TODO: редактирование билета? Нужно ли?
    // updateTicket: async function ({commit}, data) {
    // 	await api.updateTicket.update(data)
    // 		.then((resolve) => {
    // 		}).catch((e) => {
    // 			commit('createTicketError', e.data);
    // 		})
    // }
  }
}

