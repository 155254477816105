


import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { TEvent } from '@/_types/event.type';
import { TEventSettings } from '@/_types/event-settings.type';
import { TContact } from '@/_types/contact.type';
import { TGetEventSettingsParams, TPatchEventSettingsParams } from '@/_modules/events/api/event/event.api';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';
import ErrorInfo from '@/_modules/error-info/error-info.vue';
import EwCheckbox from '@/_modules/standalone-company/components/UI/ew-checkbox/ew-checkbox.vue';
import _cloneDeep from 'lodash.clonedeep';

export const CONTACT_INVITER_SETTINGS_KEY_NAME = 'contactInfoInviterSettings';
export const DEFAULT_CONTACT_INVITER_SETTINGS: any = {
  field_settings: {
    name: { isRequired: true, },
    lastName: { isRequired: true, },
    companyName: { isRequired: false, },
    jobPosition: { isRequired: false, },
    phone: { isRequired: false, },
  }
};

@Component({
  components: {
    ErrorInfo,
    EwCheckbox,
  },
})
export default class CabinetContactInviterSettings extends Vue {

  @Action('_eventStore/setEventSettingsPolled') setEventSettingsPolled: (newValue: boolean) => Promise<void>;
  @Action('_eventStore/getEventSettings') getEventSettings: (payload: TGetEventSettingsParams) => Promise<void>;
  @Action('_eventStore/patchEventSettings') patchEventSettings: (payload: TPatchEventSettingsParams) => Promise<void>;

  @Getter('_eventStore/event') event: TEvent;
  @Getter('_eventStore/eventSettings') eventSettings: TEventSettings;
  @Getter('promoPageStore/contact') readonly myself: TContact;

  public isPatchedSuccessfully: boolean = false;
  public eventSettingsError: ApiErrorResponseData = null;
  public localContactInviterSettings: any = null;

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get fieldKeys(): string[] {
    // N.B.: Using keys of default settings because we need them to be in fixed and proper order.
    // If we try returning keys of localContactInviterSettings.field_settings, the order will change after saving
    // And it won't reflect the order of fields in the form
    return Object.keys(DEFAULT_CONTACT_INVITER_SETTINGS.field_settings);
  }

  public mounted(): void {
    this.initLocalContactInviterSettings();
  }

  public initLocalContactInviterSettings(): void {
    const eventSettingsLayout = (this.eventSettings && this.eventSettings.layout) || null;
    this.localContactInviterSettings = _cloneDeep((eventSettingsLayout && eventSettingsLayout[CONTACT_INVITER_SETTINGS_KEY_NAME]) || DEFAULT_CONTACT_INVITER_SETTINGS || null);
  }

  public getShowMeetingsSetting(): boolean {

    // N.B. show_meetings setting is being set in admin.eventswallet.com
    if (
      !this.eventSettings
      || !Object.prototype.hasOwnProperty.call(this.eventSettings, 'show_meetings')
      || typeof this.eventSettings.show_meetings !== 'boolean'
    ) {
      return null;
    }

    return this.eventSettings.show_meetings;
  }

  public async sendPatchEventSettings(): Promise<void> {

    await this.getEventSettings({ eventId: this.eventId });
    const existingEventSettingsLayout = _cloneDeep((this.eventSettings && this.eventSettings.layout) || {});

    const payload: any = {
      eventId: this.eventId,
      layout: {
        ...existingEventSettingsLayout,
        [CONTACT_INVITER_SETTINGS_KEY_NAME]: this.localContactInviterSettings,
      },
      show_meetings: this.getShowMeetingsSetting()
    };

    await this.patchEventSettings(payload);
    await this.setEventSettingsPolled(false);
    await this.getEventSettings({ eventId: this.eventId });

    this.initLocalContactInviterSettings();
  }

  public isContactInfoFieldEditable(fieldKey: string): boolean {
    return (fieldKey !== 'name') && (fieldKey !== 'lastName');
  }

  public onCheckboxInput(): void {
    this.sendPatchEventSettings();
  }

}
