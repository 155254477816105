


import { Component, Vue } from 'vue-property-decorator';
import SignIn from '@/_components/auth/sign-in/sign-in.vue';
import SignUp from '@/_components/auth/sign-up/sign-up.vue';
import { Getter } from 'vuex-class';

@Component({
  name: 'auth-popup',
  components: {
    SignIn,
    SignUp
  },
})
export default class AuthPopup extends Vue {
  @Getter('authStore/isEnabledSignUp') isEnabledSignUp: boolean;
}
