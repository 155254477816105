import {AxiosRequestConfig, AxiosResponse, CancelToken} from 'axios';
import BaseApi from '@/_types/api/base.api.class';
import { TEvent } from '@/_types/event.type';
import { TContact } from '@/_types/contact.type';
import { TListOfBroadcastsItem } from '@/_types/list-of-broadcasts.type';
import { TApiListResponse } from '@/_types/api/api-list-response.type';
import { TEventRequest } from '@/_modules/events/types/event.type';
import { TContactTag } from '@/_types/contact-tag.type';
import EventHelper from '@/_helpers/event.helper';
import ContactHelper from '@/_helpers/contact.helper';
import i18n from '@/i18n';
import { TEventSettings } from '@/_types/event-settings.type';

export type TGetEventParams = {
  eventId: number;
}

export type TGetEventSettingsParams = {
  eventId: number;
}

export type TPatchEventSettingsParams = {
  eventId: number;
  layout: any;
}

export type TGetEventContactParams = {
  eventId: number;
  acceptLanguage?: string;
}

export type TEditEventParams = {
  eventId: number;
  formData: TEventRequest;
}

export type TGetListOfContactTagsParams = {
  eventId: number;
}

export type TCreateContactTagRequestParams = {
  eventId: number;
  name: string;
  parent_id?: number;
}

export type TCreateEventTagRequestParams = {
  eventId: number;
  name: string;
  parent_id?: number;
  parent?: number;
}

export type TDeleteContactTagParams = {
  eventId: number;
  tagId: number;
}

export type TPutEventEditorParams = {
  eventId: number;
  contactId: number;
}

export type TDeleteEventEditorParams = {
  eventId: number;
  contactId: number;
}

export type TEventRestrictionsParams = {
  eventId: number;
}

export type TEventRestrictions = {
  eventId: number;
  id: number;
  restrictions_data: string[];
}

export type TRegisterUserParams = {
  event_id: number;
  event_access?: boolean;
  email: string;
  password?: string;
  first_name?: string;
  last_name?: string;
  middle_name?: string;
  birthday?: string;
  country?: string;
  city?: string;
  facebook?: string;
  twitter?: string;
  instagram?: string;
  telegram?: string;
  skype?: string;
  wechat?: string;
  viber?: string;
  photo_url?: string;
  company_name?: string;
  company_description?: string;
  company_logo?: string;
  company_position?: string;
  company_website?: string;
}

export type TRegisterContactParams = {
  event_id: number;
  email: string;
  password: string;
  platform: string;
  device_id: string;
  device_token?: string;
  code?: string;
  city?: string;
  company_description?: string;
  company_name?: string;
  company_position?: string;
  company_website?: string;
  country?: string;
  lang?: string;
  name?: string;
  phone?: string;
  photo_url?: string;
  surname?: string;
  tags?: number[];
}

export type TRegisterContactResponse = {
  token?: string;
  responseType?: 'success' | 'user_exists';
}

export class EventApi extends BaseApi {

  public async getEvent(params: TGetEventParams, cancelToken?: CancelToken): Promise<TEvent> {
    const { eventId } = params;
    const config: AxiosRequestConfig = {
      url: `event/${eventId}`,
      method: 'GET',
      cancelToken,
    };

    let response;

    try {
      response = await this.axios.request<any>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return EventHelper.responseToEventConverter(response.data);
  }

  public async getEventSettings(params: TGetEventSettingsParams, cancelToken?: CancelToken): Promise<TEventSettings> {
    const { eventId } = params;
    const config: AxiosRequestConfig = {
      url: `event/${eventId}/setting`,
      method: 'GET',
      cancelToken,
    };

    let response;

    try {
      response = await this.axios.request<any>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data;
  }

  public async patchEventSettings(params: TPatchEventSettingsParams, cancelToken?: CancelToken): Promise<void> {
    const { eventId, layout } = params;
    const config: AxiosRequestConfig = {
      url: `event/${eventId}/setting`,
      method: 'PATCH',
      params: {
        layout: JSON.stringify(layout)
      },
      cancelToken,
    };

    try {
      await this.axios.request<any>(config);
    } catch (error) {
      throw this.responseError(error);
    }

  }

  public async createEvent(params: TEventRequest, cancelToken?: CancelToken): Promise<TEvent> {

    const config: AxiosRequestConfig = {
      url: 'event',
      method: 'POST',
      cancelToken,
      params: params
    };

    let response;
    try {
      response = await this.axios.request<any>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return EventHelper.responseToEventConverter(response.data);
  }

  public async editEvent(params: TEditEventParams, cancelToken?: CancelToken): Promise<TEvent> {
    const { eventId, formData } = params;

    const config: AxiosRequestConfig = {
      url: `event/${eventId}`,
      method: 'PATCH',
      cancelToken,
      params: formData
    };

    let response;
    try {
      response = await this.axios.request<any>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return EventHelper.responseToEventConverter(response.data);
  }

  public async getContact(params: TGetEventContactParams, cancelToken?: CancelToken): Promise<TContact> {
    const { eventId, acceptLanguage } = params;
    const config: AxiosRequestConfig = {
      url: `event/${eventId}/contact/info`,
      method: 'GET',
      headers: { 'Accept-Language': acceptLanguage || i18n.locale },
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<any>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return ContactHelper.responseToContactConverter(response.data);
  }

  public async getListOfBroadcasts(params: TGetEventContactParams,
    cancelToken?: CancelToken): Promise<TApiListResponse<TListOfBroadcastsItem>> {
    const { eventId } = params;
    const config: AxiosRequestConfig = {
      url: `event/${eventId}/stream/list`,
      method: 'GET',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TApiListResponse<any>>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    response.data.List = response.data.List.map(item => {
      return EventHelper.responseToBroadcastItemConverter(item);
    });

    return response.data;
  }

  public async getListOfContactTags(params: TGetListOfContactTagsParams,
    cancelToken?: CancelToken): Promise<TContactTag[]> {
    const { eventId } = params;
    const config: AxiosRequestConfig = {
      url: `event/${eventId}/contact_tag/list`,
      method: 'GET',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TApiListResponse<any>>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data.List.map(item => {
      return EventHelper.responseToContactTagConverter(item);
    });
  }

  public async createContactTag(params: TCreateContactTagRequestParams, cancelToken?: CancelToken): Promise<void> {
    const { eventId, name } = params;
    const config: AxiosRequestConfig = {
      url: `event/${eventId}/contact_tag`,
      method: 'POST',
      cancelToken,
      params: { name }
    };

    try {
      await this.axios.request<void>(config);
    } catch (error) {
      throw this.responseError(error);
    }

  }

  public async deleteContactTag(params: TDeleteContactTagParams, cancelToken?: CancelToken): Promise<void> {
    const { eventId, tagId } = params;

    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/contact_tag/${tagId}`,
      method: 'DELETE',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<void>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  }

  public async getListOfEventTags(params: TGetListOfContactTagsParams,
    cancelToken?: CancelToken): Promise<TContactTag[]> {
    const { eventId } = params;
    const config: AxiosRequestConfig = {
      url: `event/${eventId}/tag/list`,
      method: 'GET',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TApiListResponse<any>>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data.List.map(item => {
      return EventHelper.responseToContactTagConverter(item);
    });
  }

  public async createEventTag(params: TCreateEventTagRequestParams, cancelToken?: CancelToken): Promise<void> {
    const { eventId, name, parent } = params;
    const config: AxiosRequestConfig = {
      url: `event/${eventId}/tag`,
      method: 'POST',
      cancelToken,
      params: { name, parent }
    };

    try {
      await this.axios.request<void>(config);
    } catch (error) {
      throw this.responseError(error);
    }

  }

  public async deleteEventTag(params: TDeleteContactTagParams, cancelToken?: CancelToken): Promise<void> {
    const { eventId, tagId } = params;
    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/tag/${tagId}`,
      method: 'DELETE',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<void>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  }

  public async registerUser(params: TRegisterUserParams, cancelToken?: CancelToken): Promise<any> {

    const { event_id } = params;
    const preparedParams: any = Object.keys(params).reduce((resultObject: any, key) => {
      if (key !== 'event_id') {
        resultObject[key] = params[key as keyof TRegisterUserParams];
      }

      return resultObject;
    }, {});

    const config: AxiosRequestConfig = {
      url: `/event/${event_id}/register/user`,
      method: 'POST',
      cancelToken,
      params: preparedParams
    };

    let response;
    try {
      response = await this.axios.request<any>(config);
    } catch (error) {
      return this.responseError(error);
    }

    return response;
  }

  public async registerContact(params: TRegisterContactParams,
    cancelToken?: CancelToken): Promise<TRegisterContactResponse> {
    const { event_id } = params;
    const preparedParams: any = Object.keys(params).reduce((resultObject: any, key) => {
      if (key !== 'event_id') {
        resultObject[key] = params[key as keyof TRegisterContactParams];
      }

      return resultObject;
    }, {});

    const config: AxiosRequestConfig = {
      url: `/event/${event_id}/register/contact`,
      method: 'POST',
      cancelToken,
      params: preparedParams
    };

    const result: TRegisterContactResponse = {
      token: null,
      responseType: null,
    };
    let response;
    let responseError: any;
    try {
      response = await this.axios.request<TRegisterContactResponse>(config);
    } catch (error) {
      responseError = this.responseError(error).originalError;
    }

    if (response && response.data && response.data.token) {
      this.token = response.data.token;
      result.token = this.token;
      result.responseType = 'success';
    } else if (responseError && responseError.response && responseError.response.status === 403) {
      result.responseType = 'user_exists';
    }
    return result;
  }

  public async putEventEditor(params: TPutEventEditorParams, cancelToken?: CancelToken): Promise<void> {
    const { eventId, contactId } = params;
    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/contact/${contactId}/editor`,
      method: 'PUT',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<void>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  }

  public async deleteEventEditor(params: TDeleteEventEditorParams, cancelToken?: CancelToken): Promise<void> {
    const { eventId, contactId } = params;
    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/contact/${contactId}/editor`,
      method: 'DELETE',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<void>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  }

  public async eventRestrictions(params: TEventRestrictionsParams, cancelToken?: CancelToken): Promise<void> {
    const { eventId } = params;
    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/restrictions`,
      method: 'GET',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<void>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  }

  public async patchIsGoing(params: { eventId: number; isGoing: boolean }): Promise<boolean> {
    const { eventId, isGoing } = params;
    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/participant`,
      method: 'PATCH',
      params: { going: isGoing },
    };

    let response: AxiosResponse;
    let result = false;
    try {
      response = await this.axios.request<any>(config);
      result = response && response.status === 202;
    } catch (error) {
      throw this.responseError(error);
    }

    return result;
  }

}

const eventApi = new EventApi();
export default eventApi;
