import BaseApi from '@/_types/api/base.api.class';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import {TUser} from '@/_types/user.type';

// Needed for the newer Auth API
export type TSendLoginRequestParams = {
  login: string;
  password: string;
  platform: string;
  device_id: string;
  device_token?: string;
}

export type TLoginByOauthProviderRequestParams = {
  token: string;
  platform: string;
  device_id: string;
  device_token?: string;
}

type TLoginByOauthProviderResponse = {
  token: string;
  user: TUser;
}

// Needed for the newer Auth API
export type TPasswordChangeConfirmRequestParams = {
  confirmation_id: string;
  code: string;
  password: string;
  platform: string;
  device_id: string;
  device_token?: string;
}

// Needed for the older Auth API
export type TConfirmCodeRequestParams = {
  confirmation_id: string;
  code: string;
  platform: string;
  device_id: string;
  device_token?: string;
}

export type TUserAuthDataConfig = {
  allow_all_email: boolean;
  allow_all_sms: boolean;
  lang: string;
  time_region: string;
}
export type TUserAuthData = {
  id: number;
  phone: string;
  config: TUserAuthDataConfig;
}

export type TConfirmCodeResponseData = {
  token: string;
  user: TUserAuthData;
}

export type TLoginByTokenRequestParams = {
  token: string;
  platform: string;
  device_id: string;
  device_token?: string;
}

export type TLoginByTokenResponse = {
  token: string;
}

export class LoginApi extends BaseApi {
  public async resendCode(payload: { confirmation_id: string }): Promise<void> {
    const config: AxiosRequestConfig = {
      url: '/resend_code',
      method: 'POST',
      params: payload,
    };

    let response;
    try {
      response = await this.axios.request(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data;
  }

  public async emailLookup(payload: TSendLoginRequestParams): Promise<{ email: string }> {
    const config: AxiosRequestConfig = {
      url: '/user/email/lookup',
      method: 'GET',
      params: payload,
    };

    let response;
    try {
      response = await this.axios.request(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data;
  }

  public async signInReq(payload: TSendLoginRequestParams): Promise<{ token: string }> {
    const config: AxiosRequestConfig = {
      url: 'login',
      method: 'POST',
      params: payload,
    };

    let response;
    try {
      response = await this.axios.request(config);
    } catch (error) {
      throw this.responseError(error);
    }

    this.token = response.data.token || null;
    return response.data;
  }

  public async signUpReq(payload: TSendLoginRequestParams): Promise<{ token: string }> {
    const config: AxiosRequestConfig = {
      url: 'registration',
      method: 'POST',
      params: payload,
    };

    let response;
    try {
      response = await this.axios.request(config);
    } catch (error) {
      throw this.responseError(error);
    }

    this.token = response.data.token || null;
    return response.data;
  }

  // TODO: refactor? Do we need TLoginByOauthProviderResponse, or <{ token: string }> will be enough?
  public async loginByGoogle(payload: TLoginByOauthProviderRequestParams): Promise<TLoginByOauthProviderResponse> {
    const config: AxiosRequestConfig = {
      url: 'login_by_google',
      method: 'POST',
      params: payload,
    };

    let response;
    try {
      response = await this.axios.request(config);
    } catch (error) {
      throw this.responseError(error);
    }

    this.token = response.data.token || null;
    return response.data;
  }

  // TODO: refactor? Do we need TLoginByOauthProviderResponse, or <{ token: string }> will be enough?
  public async loginByFacebook(payload: TLoginByOauthProviderRequestParams): Promise<TLoginByOauthProviderResponse> {
    const config: AxiosRequestConfig = {
      url: 'login_by_facebook',
      method: 'POST',
      params: payload,
    };

    let response;
    try {
      response = await this.axios.request(config);
    } catch (error) {
      throw this.responseError(error);
    }

    this.token = response.data.token || null;
    return response.data;
  }

  // TODO: refactor? Do we need TLoginByOauthProviderResponse, or <{ token: string }> will be enough?
  public async loginByLinkedIn(payload: TLoginByOauthProviderRequestParams): Promise<TLoginByOauthProviderResponse> {
    const config: AxiosRequestConfig = {
      url: 'login_by_linkedin',
      method: 'POST',
      params: payload,
    };

    let response;
    try {
      response = await this.axios.request(config);
    } catch (error) {
      throw this.responseError(error);
    }

    this.token = response.data.token || null;
    return response.data;
  }

  public async forgotPasswordReq(login: string): Promise<{ confirmation_id: string }> {
    const config: AxiosRequestConfig = {
      url: 'forgot_password',
      method: 'POST',
      params: { login },
    };

    let response;
    try {
      response = await this.axios.request(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data;
  }

  public async changePasswordReq(payload: TPasswordChangeConfirmRequestParams): Promise<{ token: string }> {
    const config: AxiosRequestConfig = {
      url: 'password_change_confirm',
      method: 'POST',
      params: payload,
    };

    let response;
    try {
      response = await this.axios.request(config);
    } catch (error) {
      throw this.responseError(error);
    }

    this.token = response.data.token || null;
    return response.data;
  }

  /* Old auth using only phone, API v 1.0.0 */
  public async phoneReq(phone: string): Promise<{ confirmation_id: string }> {
    const config: AxiosRequestConfig = {
      url: 'login',
      method: 'POST',
      params: { phone },
    };

    let response;
    try {
      response = await this.axios.request(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data;
  }

  public async confirmCode(confirmCodeParams: TConfirmCodeRequestParams): Promise<TConfirmCodeResponseData> {
    const config: AxiosRequestConfig = {
      url: 'login_confirm',
      method: 'POST',
      params: confirmCodeParams,
    };

    let response: AxiosResponse<TConfirmCodeResponseData>;
    try {
      response = await this.axios.request<TConfirmCodeResponseData>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    this.token = response.data.token || null;
    return response.data;
  }

  public async loginByToken(loginByTokenParams: TLoginByTokenRequestParams): Promise<TLoginByTokenResponse> {
    const config: AxiosRequestConfig = {
      url: 'login_by_token',
      method: 'POST',
      params: loginByTokenParams,
    };

    let response: AxiosResponse<TLoginByTokenResponse>;
    try {
      response = await this.axios.request<TLoginByTokenResponse>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    this.token = response.data.token || null;
    return response.data;
  }

}

const loginApi = new LoginApi();
export default loginApi;
