


import {Vue, Component, Prop, Watch, Ref} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';

export type TEwSharerSharingTexts = {
  text: string;
  title: string;
}

@Component({
  components: {
    QrcodeVue: (): any => import(/* webpackPrefetch: true */ 'qrcode.vue')
  }
})
export default class EwSharer extends Vue {

  @Ref('sharerInput') public linkInput: HTMLInputElement;
  @Ref('copierWrap') public copierWrap: HTMLElement;
  @Ref('sharerReference') public sharerReference: HTMLDivElement;

  @Getter('ewSharerStore/isSharerVisible') public readonly isSharerVisible: boolean;
  @Getter('ewSharerStore/urlToShare') public readonly urlToShare: string;
  @Getter('ewSharerStore/shareEventTarget') public readonly shareEventTarget: Element;

  @Action('ewSharerStore/openSharer') openSharer: (eventTarget: Element, url?: string) => void;
  @Action('ewSharerStore/closeSharer') closeSharer: () => void;

  public isWechatBoxVisible: boolean = false;
  public top: string = '47%';
  public left: string = '47%';
  public ignoreClickOutside: boolean = false;

  @Prop({
    type: Object,
    default: () => {
      return {
        text: '',
        title: '',
      };
    }
  })
  public readonly sharingTexts: TEwSharerSharingTexts;

  @Watch('isSharerVisible', { immediate: true })
  public onIsSharerVisibleChange(newVal: boolean): void {
    if (newVal) {
      this.temporaryIgnoreClickOutside();
      this.repositionPopup();
    }

    this.hideWechatOverlay();
  }

  @Watch('shareEventTarget', { immediate: true, deep: true })
  public onShareEventTargetChange(newVal: Element): void {
    if (!newVal) {
      return;
    }
    this.repositionPopup();
  }

  public mounted(): void {
    document.addEventListener('click', this.clickOutside);
  }

  public beforeDestroy(): void {
    document.removeEventListener('click', this.clickOutside);
  }

  public hideSharer(): void {
    this.hideWechatOverlay();
    this.closeSharer();
  }

  public toggleSharer(): void {
    this.temporaryIgnoreClickOutside();
  }

  public temporaryIgnoreClickOutside(): void {
    this.ignoreClickOutside = true;
    setTimeout(() => {
      this.ignoreClickOutside = false;
    }, 200);
  }

  public clickOutside(event: MouseEvent): void {
    if (this.ignoreClickOutside) {
      return;
    }

    let isChildOfSharer = false;
    let el = event.target as HTMLElement;

    if (!el || !el.parentNode) {
      return;
    }

    while (el.parentNode
      && (el.parentNode as HTMLElement).tagName
      && ((el.parentNode as HTMLElement).tagName.toUpperCase() !== 'BODY')
    ) {
      if ((el.parentNode as HTMLElement).classList.contains('sharer')) {
        isChildOfSharer = true;
        break;
      }
      el = el.parentNode as HTMLElement;
    }

    if (!(event.target as HTMLElement).classList.contains('sharer')
      && (isChildOfSharer === false)
    ) {
      this.hideSharer();
    }
  }

  public repositionPopup(): void {
    const targetRect: DOMRect = this.shareEventTarget.getBoundingClientRect();
    const sharerRect: DOMRect = this.sharerReference.getBoundingClientRect();

    const isSharerReady: boolean = sharerRect.width > 0 && sharerRect.height > 0;

    if (!isSharerReady) {
      this.$nextTick(() => {
        this.repositionPopup();
      });
      return;
    }

    let newTop: number = (window.scrollY + targetRect.top + targetRect.height);
    let newLeft: number = (window.scrollX + targetRect.left + targetRect.width);

    const viewportWidth: number = window.innerWidth;
    const viewportHeight: number = window.innerHeight;

    const isFittingVertically: boolean = (newTop + sharerRect.height <= viewportHeight);
    const isFittingHorizontally: boolean = (newLeft + sharerRect.width <= viewportWidth);

    if (!isFittingVertically) {
      newTop = newTop - sharerRect.height - targetRect.height;
    }

    if (!isFittingHorizontally) {
      newLeft = newLeft - sharerRect.width - targetRect.width;
    }

    this.top = newTop.toFixed(0) + 'px';
    this.left = newLeft.toFixed(0) + 'px';
  }

  public copyToClipBoard(): boolean {
    this.linkInput.select();
    document.execCommand('copy');
    this.linkInput.blur();

    // visual feedback
    this.copierWrap.classList.add('copylink-done');
    setTimeout(() => {
      this.copierWrap.classList.remove('copylink-done');
    }, 7000);

    return false;
  }

  public share(socNetworkName: string): void {
    const openSharer = (url: string, w: number, h: number, windowName: string): void => {
      const top: number = (screen.height > h ? (screen.height / 3 - h / 2) : 0);
      const left: number = Math.round(screen.width / 2 - w / 2);
      const windowRef: Window = window.open(url, windowName, 'left=' + left + ',top=' + top + ',width=' + w + ',height=' + h + ',personalbar=0,toolbar=0,scrollbars=1,resizable=1');
      windowRef.focus();
    };

    const prepareURL = (): string => {
      const dict: {[key: string]: string} = {
        facebook: 'https://www.facebook.com/sharer/sharer.php?u=[url]',
        // twitter: 'https://twitter.com/intent/tweet?url=[url]&text=[text]&via=eventswallet',// TODO: в параметре via должен быть твиттер-аккаунт, а у нас его нет
        telegram: 'https://telegram.me/share/url?url=[url]',
        vk: 'https://vk.com/share.php?url=[url]',
        linkedin: 'https://www.linkedin.com/shareArticle?mini=true&url=[url]&title=[title]',
      };

      if (socNetworkName && Object.prototype.hasOwnProperty.call(dict, socNetworkName)) {
        return dict[socNetworkName]
          .replace('[url]', encodeURIComponent(this.urlToShare))
          .replace('[text]', encodeURIComponent(this.sharingTexts.text))
          .replace('[title]', encodeURIComponent(this.sharingTexts.title));
      }

      return '';
    };

    const shareEntryPointUrl: string = prepareURL();
    if (shareEntryPointUrl) {
      openSharer(shareEntryPointUrl, 400, 500, 'sharerWindow');
    }

  }

  public showWechatOverlay(): void {
    this.isWechatBoxVisible = true;
  }

  public hideWechatOverlay(): void {
    this.isWechatBoxVisible = false;
  }

}
