


import Component from 'vue-class-component';
import {Vue} from 'vue-property-decorator';
import DeviceHelper from '@/_helpers/device.helper';
import CookiesHelper from '@/_helpers/cookies-helper';
import IconCloseQrPopup from '@/_modules/icons/components/qrpopup/icon-close-qr-popup.vue';

const MOB_APP_INVITER_COOKIE_NAME = 'mobile_app_inviter_shown';
const MOB_APP_INVITER_COOKIE_VALUE = '1';

@Component({
  components: {
    IconCloseQrPopup
  }
})
export default class MobileAppInviter extends Vue {

  public showFlag: boolean = true;

  public get appUrlIos(): string {
    return 'https://apps.apple.com/app/events-wallet/id1506448963';
  }

  public get appUrlAndroid(): string {
    return 'https://play.google.com/store/apps/details?id=com.biletum&hl=' + this.$i18n.locale;
  }

  public get isMobileAppInviterShown(): boolean {
    const hasBeenShown: boolean = (CookiesHelper.readCookie(MOB_APP_INVITER_COOKIE_NAME) === '1');
    const isNotHomePage: boolean = this.$route
      && this.$route.name
      && this.$route.name !== 'home'
      && this.$route.name !== 'standalone-company'
      && this.$route.name !== 'event-list'
      && this.$route.name !== 'event-list-my';
    return this.showFlag && isNotHomePage && !hasBeenShown && DeviceHelper.isUserAgentMobile() && (this.isIOS || this.isAndroid);
  }

  public get isIOS(): boolean {
    return DeviceHelper.isIOS();
  }

  public get isAndroid(): boolean {
    return DeviceHelper.isAndroid();
  }

  public get mainButtonHref(): string {
    if (this.isIOS) {
      return this.appUrlIos;
    }

    if (this.isAndroid) {
      return this.appUrlAndroid;
    }

    return '';
  }

  public appButtonClickHandler(): void {

    if (this.isIOS) {
      window.location.href = this.appUrlIos;
    } else if (this.isAndroid) {
      window.location.href = this.appUrlAndroid;
    } else {
      this.showFlag = false;
    }

  }

  public proceedButtonClickHandler(): void {
    CookiesHelper.createCookie({
      name: MOB_APP_INVITER_COOKIE_NAME,
      value: MOB_APP_INVITER_COOKIE_VALUE,
    });
    this.showFlag = false;
  }
}
