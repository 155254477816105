import BaseApi from '@/_types/api/base.api.class';
import { AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import { TApiListResponse } from '@/_types/api/api-list-response.type';
import { TMeeting } from '@/_types/meeting/meeting.type';
import MeetingsHelper from '@/_helpers/meetings.helper';

export type TGetMeetingsParams = {
  event_id: number;
  user_id: number;
};

export enum MeetingCommunicationTypes {
  SKYPE = 'skype',
  ZOOM = 'zoom',
  WEB = 'web',
  CALL = 'call',
  OFFLINE = 'offline',
}

export type TRequestMeetingParams = {
  event_id: number;
  user_id: number;
  date_start: string;
  date_end: string;
  title?: string;
  communication_type?: MeetingCommunicationTypes;
  communication_data?: string;
};

export type TConfirmMeetingParams = {
  event_id: number;
  meeting_id: number;
};

export type TCancelMeetingParams = {
  event_id: number;
  meeting_id: number;
};

export type TGetManagedMeetingsParams = {
  eventId: number;
  offset?: number;
  limit?: number;
}

export class MeetingsApi extends BaseApi {

  public async getMeetings(params: TGetMeetingsParams, cancelToken?: CancelToken): Promise<TMeeting[]> {
    const config: AxiosRequestConfig = {
      url: `/event/${params.event_id}/user/${params.user_id}/meeting/list?limit=1000`,
      method: 'GET',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TApiListResponse<any>>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data.List.map(item => {
      return MeetingsHelper.responseToMeetingsConverter(item);
    });
  }

  public async getManagedMeetings(params: TGetManagedMeetingsParams, cancelToken?: CancelToken): Promise<TApiListResponse<TMeeting>> {
    const { offset, limit } = params;
    const config: AxiosRequestConfig = {
      url: `/event/${params.eventId}/meeting/list`,
      method: 'GET',
      params: {
        offset: offset || null,
        limit: limit || null
      },
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TApiListResponse<TMeeting>>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    response.data.List = response.data.List.map(item => {
      return MeetingsHelper.responseToMeetingsConverter(item);
    });

    return response.data;
  }

  public async requestMeeting(params: TRequestMeetingParams): Promise<AxiosResponse<TMeeting>> {
    const config: AxiosRequestConfig = {
      url: `/event/${params.event_id}/user/${params.user_id}/meeting`,
      method: 'POST',
      params: Object.assign({ event_id: undefined, user_id: undefined }, params),
    };

    try {
      const response = await this.axios.request(config);
      if (response && response.data) {
        response.data = MeetingsHelper.responseToMeetingsConverter(response.data);
      }
      return response;
    } catch (error) {
      throw this.responseError(error);
    }
  }

  public async confirmMeeting(params: TConfirmMeetingParams): Promise<AxiosResponse> {
    const config: AxiosRequestConfig = {
      url: `/event/${params.event_id}/meeting/${params.meeting_id}/confirm`,
      method: 'PATCH',
    };

    try {
      return await this.axios.request(config);
    } catch (error) {
      throw this.responseError(error);
    }
  }

  public async cancelMeeting(params: TCancelMeetingParams): Promise<AxiosResponse> {
    const config: AxiosRequestConfig = {
      url: `/event/${params.event_id}/meeting/${params.meeting_id}/cancel`,
      method: 'PATCH',
    };

    try {
      return await this.axios.request(config);
    } catch (error) {
      throw this.responseError(error);
    }
  }

}

const meetingsApi = new MeetingsApi();
export default meetingsApi;
