import moment from 'moment';
import UrlHelper from '@/_helpers/url.helper';
import { TEvent } from '@/_types/event.type';
import { TimeStatus } from '@/_types/time-status.enum';
import { TContact } from '@/_types/contact.type';
import DateTimeHelper from '@/_helpers/date-time.helper';
import {TListOfBroadcastsItem} from '@/_types/list-of-broadcasts.type';
import {TContactTag} from '@/_types/contact-tag.type';
import ContactHelper from '@/_helpers/contact.helper';
import PromoPageHelper from '@/_helpers/promo-page.helper';

export default class EventHelper {

  public static redirectToEventDomain(eventInfo: TEvent): boolean {

    let result = false;

    if (eventInfo.event_domain) {
      const url = eventInfo.event_domain;
      if (!UrlHelper.isSameDomain(url)) {
        (window as any).location = (window as any).location.protocol + '//' + url;
        result = true;
      }
    }

    return result;
  }

  public static getEventTimeStatus(event: TEvent): TimeStatus {
    if (!event || (!event.date_start && !event.date_end)) {
      return TimeStatus.UNKNOWN;
    }

    const now = moment();
    const start = event.date_start ? moment(event.date_start) : null;
    const end = event.date_end ? moment(event.date_end) : null;

    if (start && end) {
      if (now.isBefore(start, 'day')) {
        return TimeStatus.FUTURE;
      } else if (now.isAfter(end, 'day')) {
        return TimeStatus.PAST;
      } else {
        return TimeStatus.PRESENT;
      }
    } else if (start) {
      if (now.isBefore(start, 'day')) {
        return TimeStatus.FUTURE;
      } else {
        return TimeStatus.PRESENT;
      }
    } else if (end) {
      if (now.isAfter(end, 'day')) {
        return TimeStatus.PAST;
      } else {
        return TimeStatus.PRESENT;
      }
    }

    return TimeStatus.UNKNOWN;
  }

  public static getEventExpireTimestamp(event: TEvent): number {
    return (event && event.date_end) ? moment(event.date_end).unix() : 0;
  }

  public static isContactEventOrganizer(event: TEvent, contact: TContact): boolean {
    if (!event || !event.creator_user_id || !contact || !contact.user_id) {
      return false;
    }
    return event.creator_user_id === contact.user_id;
  }

  public static isContactEventEditor(event: TEvent, contact: TContact): boolean {
    const eventEditors = (event && event.editors) || [];
    if (!contact) {
      return false;
    }
    return !!eventEditors.find((editor: TContact) => editor.id === contact.id);
  }

  public static responseToEventConverter(responseObj: any): TEvent {
    if(!responseObj) return null;
    return Object.assign({}, responseObj, {
      date_start: DateTimeHelper.apiDateToDate(responseObj.date_start),
      date_end: DateTimeHelper.apiDateToDate(responseObj.date_end),
    });
  }

  public static responseToBroadcastItemConverter(responseObj: any): TListOfBroadcastsItem {
    if(!responseObj) return null;
    return Object.assign({}, responseObj, {
      contact: ContactHelper.responseToContactConverter(responseObj.contact),
      promopage: PromoPageHelper.responseToPromoPageConverter(responseObj.promopage),
    });
  }

  public static responseToContactTagConverter(responseObj: any): TContactTag {
    if(!responseObj) return null;
    return Object.assign({}, responseObj);
  }
}
