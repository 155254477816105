import api from "../../api";
import AxiosCanceler from '@/_types/api/axios-canceler.class';
import PromoPageHelper from '@/_modules/promo/helpers/promo-page.helper';
import _cloneDeep from 'lodash.clonedeep';
import {BroadcastType} from "@/_types/broadcasts/broadcast-type.enum";

export default {
  namespaced: true,
  state: {
    promoCompaniesByExternalId: {},
    unpinnedMediaItemsByExternalId: {},
    promoPageError: '',
    promoOwnerId: null,
    editPromoPageLoading: false,
    editPromoPageSuccess: false,
    createPromoPageLoading: false,
    createPromoPageSuccess: false,
    promoPageListLoading: false,
    promoOwnerContactId: null,
    contactListType: 'all',
    navigationTab: 'profile',
    companiesListType: 'all',
    pavilions: null,
    cancelers: {
      getPromoPage: null,
      promoPageListAll: null,
      promoPageListMy: null,
      getProductsFromPromoPage: null,
    },
    promoPage: {
      attached_users: [
        {
          role: '',
          user: {
            city: 'City',
            companies: null,
            country: '',
            files: null,
            first_name: '',
            id: null,
            last_name: '',
            middle_name: '',
            phone: '',
            photo_url: null
          }
        }
      ],
      website: '',
      description: '',
      external_id: null,
      id: null,
      is_creator: null,
      is_favorite: false,
      country: {},
      city: {},
      logo_url: '',
      photos: [],
      files: [],
      title: '',
      products: [], // company product list
      tags: [], // company tags (categories / subcategories)
    },
    myPromoPage: {
      attached_users: [
        {
          role: '',
          user: {
            city: '',
            companies: null,
            country: '',
            files: null,
            first_name: '',
            id: null,
            last_name: '',
            middle_name: '',
            phone: '',
            photo_url: null
          }
        }
      ],
      website: '',
      description: '',
      external_id: null,
      id: null,
      country: {},
      city: {},
      is_creator: null,
      is_favorite: false,
      logo_url: '',
      photos: [],
      files: [],
      title: '',
      products: [], // company product list
      tags: [], // company tags (categories / subcategories)
    },
    promoPageList: [{
      attached_users: [
        {
          role: '',
          user: {
            city: null,
            companies: null,
            country: null,
            files: null,
            first_name: null,
            id: null,
            last_name: null,
            middle_name: null,
            phone: "",
            photo_url: null
          }
        }
      ],
      website: '',
      description: '',
      external_id: null,
      id: null,
      is_creator: null,
      is_favorite: false,
      country: {},
      city: {},
      logo_url: '',
      title: '',
      products: [], // company product list
      tags: [], // company tags (categories / subcategories)
    }],
    profile: {
      attached_contacts: [
        {
          role: '',
          contact: {
            id: 0,
            event_id: 0,
            name: '',
            surname: '',
            email: '',
            photo_url: '',
            country: '',
            city: '',
            company_name: '',
            company_website: '',
            company_position: '',
            company_description: '',
            notes: '',
            tags: [],
            user: {
              id: 0,
              name: '',
              phone: '',
              email: '',
              photo_url: ''
            },
            is_favorite: null,
            unread_messages: 0,
            video_stream_url: '',
            files: []
          },
        },
      ],
      website: '',
      description: '',
      external_id: null,
      id: null,
      is_creator: null,
      is_favorite: false,
      logo_url: '',
      title: '',
      products: [], // company product list
    },
    companiesFoundOnRequest: false,
    productsListStandalone: [], // Separate products list to be updated independently from promopage so that unneeded watchers don't get triggered
    productsListStandaloneError: '', // Separate error for products list
    myPromoPageLoading: true
  },
  getters: {
    // getCategoryList: function (state) {
    //   return state.categoryList;
    // },

    promoPageListLoading(state) {
      return state.promoPageListLoading;
    },

    promoPageList(state) {
      return state.promoPageList;
    },

    promoPage(state) {
      return state.promoPage;
    },

    myPromoPage(state) {
      return state.myPromoPage;
    },

    promoPageByExternalId: (state) => (externalId) => {
      return (state.promoCompaniesByExternalId && state.promoCompaniesByExternalId[externalId])
        ? state.promoCompaniesByExternalId[externalId]
        : null;
    },

    promoPages: (state) => {
      return Object.values(state.promoCompaniesByExternalId);
    },

    unpinnedMediaItems: (state) => {
      let items = [];
      Object.entries(state.unpinnedMediaItemsByExternalId).forEach(unpinnedMediaItems => {
        items = [ ...items, ...unpinnedMediaItems[1] ];
      });
      return items;
    },

    productsListStandalone: (state) => {
      return state.productsListStandalone || [];
    },

    editPromoPageLoading: (state) => {
      return state.editPromoPageLoading;
    },

    editPromoPageSuccess: (state) => {
      return state.editPromoPageSuccess;
    },

    companiesListType: (state) => {
      return state.companiesListType || '';
    },
  },
  mutations: {

    promoPageVideoStreamEmbedUpdated(state, { external_id, video_stream_embed /*, event_id */ }) {
      if (state.promoPage && state.promoPage.external_id === external_id) {
        state.promoPage.video_stream_embed = video_stream_embed;
      }

      if (state.promoCompaniesByExternalId[external_id]) {
        state.promoCompaniesByExternalId[external_id].video_stream_embed = video_stream_embed;
        PromoPageHelper.updateCompanyMediaItems(state.promoCompaniesByExternalId[external_id]);
      }
    },

    setPromoPageListAll(state, data) {
      // state.promoCompaniesByExternalId = {};
      // state.unpinnedMediaItemsByExternalId = {};

      if (!data || !data.List) {
        return;
      }
      const tempMap = {}; // Optimisation
      data.List.forEach((promoPage) => {
        const pp = state.promoCompaniesByExternalId[promoPage.external_id];
        if (!pp) {
          PromoPageHelper.updateCompanyMediaItems(promoPage);
          state.promoCompaniesByExternalId[promoPage.external_id] = promoPage;
          state.unpinnedMediaItemsByExternalId[promoPage.external_id] = [];
        } else {
          tempMap[promoPage.external_id] = Object.assign({}, promoPage, {
            hasActiveBroadcast: pp.hasActiveBroadcast,
            companyMediaItems: pp.companyMediaItems,
            contactIds: pp.contactIds,
            activeMediaItem: pp.activeMediaItem,
          });
          PromoPageHelper.updateCompanyMediaItems(pp);
        }
      });
      state.promoCompaniesByExternalId = Object.assign({}, state.promoCompaniesByExternalId, tempMap);
      state.promoPageList = data.List;
    },

    setActiveCompanyMediaItem(state, { externalId, id }) {
      if (!state.promoCompaniesByExternalId[externalId]) {
        return;
      }
      let activeMediaItem = null;
      const mediaItems = state.promoCompaniesByExternalId[externalId].companyMediaItems;
      for (let index = 0; index < mediaItems.length; index++) {
        mediaItems[index].isActive = mediaItems[index].id === id;
        if (mediaItems[index].isActive) {
          activeMediaItem = mediaItems[index];
        }
      }
      state.promoCompaniesByExternalId[externalId].activeMediaItem = activeMediaItem;
    },

    setCompanyMediaItemIsPaused(state, { externalId, id, isPaused }) {
      if (!state.promoCompaniesByExternalId[externalId]) {
        return;
      }

      const mediaItem = state.promoCompaniesByExternalId[externalId].companyMediaItems.find(mediaItem => {
        return mediaItem.id === id;
      });

      if (mediaItem) {
        mediaItem.isPaused = isPaused;
      }
    },

    // setCompanyMediaItemsBroadcasts(state, { externalId, broadcasts }) {
    //   if (!state.promoCompaniesByExternalId[externalId]) {
    //     return;
    //   }
    //
    //   const mediaItems = state.promoCompaniesByExternalId[externalId].companyMediaItems;
    //   for (let index = mediaItems.length - 1; index > -1; index--) {
    //     if (mediaItems[index].type === 'broadcast') {
    //       if (broadcasts.indexOf(mediaItems[index].src) < 0) {
    //         mediaItems.splice(index, 1);
    //       }
    //     }
    //   }
    //
    //   broadcasts.forEach(broadcastUrl => {
    //     const existingItem = mediaItems.find(mediaItem => {
    //       return mediaItem.type === 'broadcast' && mediaItems.src === broadcastUrl;
    //     })
    //     if (!existingItem) {
    //       mediaItems.unshift({
    //         id: `company_${externalId}_broadcast_${broadcastUrl}`,
    //         entityId: externalId,
    //         type: 'broadcast',
    //         src: broadcastUrl,
    //         isPaused: false,
    //       });
    //     }
    //   });
    //
    //   state.promoCompaniesByExternalId[externalId].hasActiveBroadcast = !!mediaItems.find((mediaItem) => {
    //     return mediaItem.type === 'broadcast';
    //   })
    //
    //   state.promoCompaniesByExternalId = Object.assign({}, state.promoCompaniesByExternalId);
    // },

    setPreparedCompanyMediaItems(state, broadcasts) {
      Object.keys(broadcasts).forEach((externalId) => {
        state.promoCompaniesByExternalId[externalId].companyMediaItems = broadcasts[externalId];
        state.promoCompaniesByExternalId[externalId].hasActiveBroadcast = true;
      });
    },

    setAllCompanyVideoMediaItemsIsPaused(state, { externalId }) {
      if (!state.promoCompaniesByExternalId[externalId]) {
        return;
      }

      state.promoCompaniesByExternalId[externalId].companyMediaItems.forEach(mediaItem => {
        if (mediaItem.type === 'video' || mediaItem.type === 'broadcast') {
          mediaItem.isPaused = true;
        }
      });
    },

    unpinMediaItem(state, { externalId, id }) {
      if (
        !state.promoCompaniesByExternalId[externalId]
        || !state.promoCompaniesByExternalId[externalId].companyMediaItems
      ) {
        return;
      }

      const mediaItems = state.promoCompaniesByExternalId[externalId].companyMediaItems;
      const item = mediaItems.find(mediaItem => {
        return mediaItem.id === id;
      });

      if (!item) {
        return;
      }

      item.isUnpinned = true;
      if (!state.unpinnedMediaItemsByExternalId[externalId]) {
        state.unpinnedMediaItemsByExternalId[externalId] = [];
      }
      const index = state.unpinnedMediaItemsByExternalId[externalId].indexOf(item);
      if (index < 0) {
        state.unpinnedMediaItemsByExternalId[externalId] = [
          ...state.unpinnedMediaItemsByExternalId[externalId],
          item,
        ];
      }

      state.unpinnedMediaItemsByExternalId = Object.assign({}, state.unpinnedMediaItemsByExternalId);
      state.promoCompaniesByExternalId[externalId].companyMediaItems = [ ...mediaItems ];
    },

    pinMediaItem(state, { externalId, id }) {
      if (
        !state.promoCompaniesByExternalId[externalId]
        || !state.promoCompaniesByExternalId[externalId].companyMediaItems
      ) {
        return;
      }

      const mediaItems = state.promoCompaniesByExternalId[externalId].companyMediaItems;
      const item = mediaItems.find(mediaItem => {
        return mediaItem.id === id;
      });

      if (!item) {
        return;
      }

      item.isUnpinned = false;
      if (!state.unpinnedMediaItemsByExternalId[externalId]) {
        state.unpinnedMediaItemsByExternalId[externalId] = [];
      }
      state.unpinnedMediaItemsByExternalId[externalId] = [ ...state.unpinnedMediaItemsByExternalId[externalId].filter(mediaItem => {
        return mediaItem.id !== id;
      }) ];

      state.unpinnedMediaItemsByExternalId = Object.assign({}, state.unpinnedMediaItemsByExternalId);
      state.promoCompaniesByExternalId[externalId].companyMediaItems = [ ...mediaItems ].slice().sort((a, b) => {
          if (a.type === 'video' || a.type === 'embed' || a.type === 'broadcast') {
            return -1;
          } else if (b.type === 'video' || b.type === 'embed' || b.type === 'broadcast') {
            return -1;
          } else {
            return 0;
          }
        }
      );
    },

    clearPromoCompanies(state) {
      state.promoCompaniesByExternalId = {};
      state.unpinnedMediaItemsByExternalId = {};
    },

    setProfile(state, data) {
      state.profile = data;
    },
    setEditPromoPageLoading(state, data) {
      state.editPromoPageLoading = data;
    },
    setEditPromoPageSuccess(state, data) {
      state.editPromoPageSuccess = data;
    },
    setCreatePromoPageLoading(state, data) {
      state.createPromoPageLoading = data;
    },
    setCreatePromoPageSuccess(state, data) {
      state.createPromoPageSuccess = data;
    },
    updatePromoCompaniesByExternalId(state, promoPage) {
      if (!promoPage || !promoPage.external_id) {
        return;
      }
      let pp = state.promoCompaniesByExternalId[promoPage.external_id];
      if (!pp) {
        PromoPageHelper.updateCompanyMediaItems(promoPage);
        state.promoCompaniesByExternalId[promoPage.external_id] = promoPage;
        state.unpinnedMediaItemsByExternalId[promoPage.external_id] = [];
      } else {
        pp = Object.assign({}, promoPage, {
          hasActiveBroadcast: pp.hasActiveBroadcast,
          companyMediaItems: pp.companyMediaItems,
          contactIds: pp.contactIds,
          activeMediaItem: pp.activeMediaItem,
        });
        PromoPageHelper.updateCompanyMediaItems(pp);
        state.promoCompaniesByExternalId[promoPage.external_id] = pp;
      }

      state.promoCompaniesByExternalId = Object.assign({}, state.promoCompaniesByExternalId);
    },
    setPromoPage(state, data) {
      let files;
      let photos;
      let products;
      let ownerId;
      let promoPageMap = {
        id: null
      };

      if (data !== null) {

        if (data.files) {
          files = data.files.map(item => {
            return item;
          })
        }
        if (data.photos) {
          photos = data.photos.map(item => {
            return item;
          })
        }
        if (data.products) {
          products = data.products.map(item => {
            return item;
          })
        }

        promoPageMap = {
          attached_contacts: !data.attached_contacts ? (() => {
            state.promoOwnerContactId = null;
            return [];
          })() : data.attached_contacts.map((item) => {
            if (item.role === 'owner') {
              ownerId = item.contact.user.id;
              state.promoOwnerContactId = item.contact.id;
            }

            return {
              role: item.role,
              contact: {
                event_id: item.contact.event_id,
                id: item.contact.id,
                is_favorite: item.contact.is_favorite,
                unread_messages: item.contact.unread_messages,
                video_stream_url: item.contact.video_stream_url,
                video_stream_embed: item.contact.video_stream_embed,
                name: item.contact.name,
                photo_url: item.contact.photo_url,
                company_name: item.contact.company_name,
                company_website: item.contact.company_website,
                company_position: item.contact.company_position,
                surname: item.contact.surname,
                tags: item.contact.tags || [],
                user: {
                  birthday: item.contact.user.birthday,
                  city: item.contact.user.city || 'city',
                  companies: item.contact.user.companies,
                  country: item.contact.user.country || 'country',
                  email: item.contact.user.email,
                  facebook: item.contact.user.facebook,
                  files: item.contact.user.files,
                  first_name: item.contact.user.first_name,
                  id: item.contact.user.id,
                  instagram: item.contact.user.instagram,
                  last_name: item.contact.user.last_name,
                  middle_name: item.contact.user.middle_name,
                  phone: item.contact.user.phone,
                  photo_url: item.contact.user.photo_url,
                  skype: item.contact.user.skype,
                  telegram: item.contact.user.telegram,
                  twitter: item.contact.user.twitter,
                  viber: item.contact.user.viber,
                  wechat: item.contact.user.wechat,
                }
              }
            }
          }),

          website: data.website,
          description: data.description,
          external_id: data.external_id,
          files: files,
          photos: photos,
          id: data.id,
          is_creator: data.is_creator,
          is_favorite: data.is_favorite,
          logo_url: data.logo_url,
          title: data.title,
          video_stream_url: data.video_stream_url,
          video_stream_embed: data.video_stream_embed,
          country: data.country,
          city: data.city,
          products: products,
          tags: data.tags ? data.tags : [],
        };
      }

      state.promoPage = promoPageMap;
      state.promoOwnerId = ownerId;
      // state.promoOwnerContactId = ownerContactId;
    },
    setMyPromoPage(state, data) {
      let files;
      let photos;
      let products;
      let ownerId;
      let ownerContactId;
      let promoPageMap = {
        id: null
      };


      if (data !== null) {

        if (data.files) {
          files = data.files.map(item => {
            return item;
          })
        }

        if (data.photos) {
          photos = data.photos.map(item => {
            return item;
          })
        }

        if (data.products) {
          products = data.products.map(item => {
            return item;
          })
        }

        promoPageMap = {
          attached_contacts: (data.attached_contacts || []).map((item) => {
            if (item.role === 'owner') {
              ownerId = item.contact.user.id;
              ownerContactId = item.contact.id;
            }
            return {
              role: item.role,
              contact: {
                event_id: item.contact.event_id,
                id: item.contact.id,
                is_favorite: item.contact.is_favorite,
                unread_messages: item.contact.unread_messages,
                video_stream_url: item.contact.video_stream_url,
                video_stream_embed: item.contact.video_stream_embed,
                name: item.contact.name,
                surname: item.contact.surname,
                photo_url: item.contact.photo_url,
                tags: item.contact.tags || [],
                user: {
                  birthday: item.contact.user.birthday,
                  city: item.contact.user.city || 'city',
                  companies: item.contact.user.companies,
                  country: item.contact.user.country || 'country',
                  email: item.contact.user.email,
                  facebook: item.contact.user.facebook,
                  files: item.contact.user.files,
                  first_name: item.contact.user.first_name,
                  id: item.contact.user.id,
                  instagram: item.contact.user.instagram,
                  last_name: item.contact.user.last_name,
                  middle_name: item.contact.user.middle_name,
                  phone: item.contact.user.phone,
                  photo_url: item.contact.user.photo_url,
                  skype: item.contact.user.skype,
                  telegram: item.contact.user.telegram,
                  twitter: item.contact.user.twitter,
                  viber: item.contact.user.viber,
                  wechat: item.contact.user.wechat,
                }
              }
            }
          }),

          website: data.website,
          description: data.description,
          external_id: data.external_id,
          files: files,
          photos: photos,
          id: data.id,
          is_creator: data.is_creator,
          is_favorite: data.is_favorite,
          logo_url: data.logo_url,
          title: data.title,
          video_stream_url: data.video_stream_url,
          video_stream_embed: data.video_stream_embed,
          country: data.country,
          city: data.city,
          products: products || [],
          tags: data.tags ? data.tags : [],
        };
      }
      state.promoOwnerId = ownerId;
      state.promoOwnerContactId = ownerContactId;
      state.myPromoPage = promoPageMap;
    },
    setMyPromoPageLoading(state, data) {
      state.myPromoPageLoading = data
    },
    setPromoPageList(state, data) {
      state.promoPageList = data.List;
    },
    promoPageError(state, data) {
      state.promoPageError = data
    },
    setContactListType(state, data) {
      state.contactListType = data;
    },
    setCompaniesListType(state, data) {
      state.companiesListType = data;
    },
    _setNavigationTab(state, data) {
      state.navigationTab = data;
    },
    setPromoPageListLoading(state, data) {
      state.promoPageListLoading = data
    },
    setCancelers(state, cancelers) {
      state.cancelers = Object.assign({}, state.cancelers, cancelers);
    },
    setPavilion(state, data){
      state.pavilions = data;
    },
    companiesFoundOnRequest(state, data){
      state.companiesFoundOnRequest = data
    },
    setProductsListStandalone(state, data){
      state.productsListStandalone = data;
    },
    setProductsListStandaloneError(state, data){
      state.productsListStandaloneError = data;
    },
  },
  actions: {

    setActiveCompanyMediaItem({ commit }, { externalId, id }) {
      commit('setActiveCompanyMediaItem', { externalId, id });
    },

    setCompanyMediaItemIsPaused({ commit }, { externalId, id, isPaused }) {
      commit('setCompanyMediaItemIsPaused', { externalId, id, isPaused });
    },

    setAllCompanyVideoMediaItemsIsPaused({ commit }, { externalId }) {
      commit('setAllCompanyVideoMediaItemsIsPaused', { externalId });
    },

    // setCompanyMediaItemsBroadcasts({ commit }, { externalId, broadcasts }) {
    //   commit('setCompanyMediaItemsBroadcasts', { externalId, broadcasts })
    // },

    /* Filters into the structure external_id → stream_url[]
     * feeds to setCompanyMediaItemsBroadcasts correspondingly
     * */
    applyActiveBroadcasts({ commit, state }, allBroadcasts) {

      // restructuring
      const restructuredAllBroadcasts = {};
      allBroadcasts.forEach((broadcast) => {
        if (
          !broadcast.details
          || broadcast.type !== BroadcastType.PROMO_CONTACT
          || !broadcast.details.promopage
          || !broadcast.details.promopage.external_id
          || !broadcast.stream_url
        ) {
          return;
        }
        const externalId = broadcast.details.promopage.external_id;
        if (!state.promoCompaniesByExternalId[externalId]) {
          return;
        }
        restructuredAllBroadcasts[externalId] = _cloneDeep(state.promoCompaniesByExternalId[externalId].companyMediaItems) || [];
        if (!(restructuredAllBroadcasts[externalId].find(item => {
          return item.src === broadcast.stream_url;
        }))) {
          restructuredAllBroadcasts[externalId].push({
            id: `company_${externalId}_broadcast_${broadcast.stream_url}`,
            entityType: 'company',
            entityId: externalId,
            type: 'broadcast',
            src: broadcast.stream_url,
            isPaused: false,
          })
        }
      });

      // setting
      commit('setPreparedCompanyMediaItems', restructuredAllBroadcasts);

    },

    unpinMediaItem({ commit }, { externalId, id }) {
      commit('unpinMediaItem', { externalId, id });
    },

    pinMediaItem({ commit }, { externalId, id }) {
      commit('pinMediaItem', { externalId, id });
    },

    clearPromoCompanies: ({ commit }) => {
      commit('clearPromoCompanies');
    },

    promoPageVideoStreamEmbedUpdated: ({ commit }, params) => {
      commit('promoPageVideoStreamEmbedUpdated', params);
    },

    promoPageCreate: async function ({ commit }, payload, event_id) {
      commit('setCreatePromoPageLoading', true);
      try {
        await api.promoPage.promoPageCreate(payload, event_id);
        commit('setCreatePromoPageLoading', false);
        commit('setCreatePromoPageSuccess', false)
        commit('setCreatePromoPageSuccess', true);
        commit('promoPageError', '');
        // commit('setPromoPageList', resolve);
      } catch (error) {
        commit('setCreatePromoPageLoading', false);
        commit('setCreatePromoPageSuccess', false);
        commit('promoPageError', error.data);
      }
    },
    promoPageListMy: async function ({ commit, state }, payload) {
      const currentCanceler = state.cancelers.promoPageListMy;
      if (currentCanceler) {
        currentCanceler.cancel();
        commit('setCancelers', { promoPageListMy: null });
      }
      const canceler = new AxiosCanceler();
      commit('setCancelers', { promoPageListMy: canceler });

      commit('setPromoPageListLoading', true);
      try {
        const response = await api.promoPage.promoPageList({ ...payload, cancelToken: canceler.cancelToken });
        commit('setPromoPageList', response);
      } catch (error) {
        commit('promoPageError', error);
      } finally {
        commit('setCancelers', { promoPageListMy: null });
        commit('setPromoPageListLoading', false);
      }
    },

    promoPageListAll: async function ({ commit, state }, { event_id }) {
      const currentCanceler = state.cancelers.promoPageListAll;
      if (currentCanceler) {
        currentCanceler.cancel();
        commit('setCancelers', { promoPageListAll: null });
      }
      const canceler = new AxiosCanceler();
      commit('setCancelers', { promoPageListAll: canceler });
      commit('setPromoPageListLoading', true);

      try {
        const response = await api.promoPage.promoPageListAll({ event_id, cancelToken: canceler.cancelToken });
        commit('setPromoPageListAll', response);
      } catch (error) {
        commit('promoPageError', error.data);
      } finally {
        commit('setCancelers', { promoPageListAll: null });
        commit('setPromoPageListLoading', false);
      }
    },
    getPromoPage: async function ({ commit, state }, payload) {
      const currentCanceler = state.cancelers.getPromoPage;
      if (currentCanceler) {
        currentCanceler.cancel();
        commit('setCancelers', { getPromoPage: null });
      }
      const canceler = new AxiosCanceler();
      commit('setCancelers', { getPromoPage: canceler });
      commit('setPromoPageListLoading', true); // TODO: getPromoPage affecting setPromoPageListLoading ?

      try {
        const response = await api.promoPage.getPromoPage({
          eventId: payload.event_id,
          externalId: payload.external_id,
          cancelToken: canceler.cancelToken,
        });
        commit('setPromoPage', response);
        commit('updatePromoCompaniesByExternalId', response);
      } catch (error) {
        commit('promoPageError', error.data);
      } finally {
        commit('setCancelers', { getPromoPage: null });
        commit('setPromoPageListLoading', false);
      }
    },
    // Asks API for promopage. Returns only promopage.products. Does not mutate state.promopage
    // Needed to update only the products list after saving.
    getProductsFromPromoPage: async function({ commit, state }, payload){
      const currentCanceler = state.cancelers.getProductsFromPromoPage;
      if (currentCanceler) {
        currentCanceler.cancel();
        commit('setCancelers', { getProductsFromPromoPage: null });
      }
      const canceler = new AxiosCanceler();
      commit('setCancelers', { getProductsFromPromoPage: canceler });

      try {
        const response = await api.promoPage.getPromoPage({
          eventId: payload.eventId,
          externalId: payload.externalId,
          cancelToken: canceler.cancelToken,
          lang: payload.lang,
        });
        if (response.products) {
          commit('setProductsListStandalone', response.products);
        }
      } catch (error) {
        commit('setProductsListStandaloneError', error.data);
      } finally {
        commit('setCancelers', { getProductsFromPromoPage: null });
      }
    },
    // getPromoPageMap: async function ({commit}, payload) {
    //   try {
    //     const response = await api.promoPage.getPromoPage({ eventId: payload.event_id, externalId: payload.external_id })
    //     commit('setPromoPageMap', response);
    //   } catch (error) {
    //     commit('setPromoPageMap', null);
    //     commit('promoPageError', error.data);
    //   }
    // },
    // clearPromoPageMap: async function ({commit}) {
    //   commit('clearPromoMap')
    // },
    getPromoPageContactId: async function ({commit}, payload) {
      commit('setPromoPageListLoading', true);

      try {
        const response = await api.promoPage.getPromoPageContactId(payload.event_id, payload.contact_id);
        commit('setPromoPage', response);
        commit('updatePromoCompaniesByExternalId', response);
      } catch (error) {
        commit('setPromoPage', null);
        commit('promoPageError', error.data);
      } finally {
        commit('setPromoPageListLoading', false);
      }
    },
    clearPromoPageContactId: async function ({commit}) {
      commit('setPromoPage', null);
    },
    getProfileByContactId: async function ({commit}, payload) {
      try {
        const response = await api.promoPage.getPromoPageContactId(payload.event_id, payload.contact_id);
        commit('setProfile', response);
      } catch (error) {
        commit('setProfile', null);
        commit('promoPageError', error.data);
      }
    },
    getMyPromoPageContactId: async function ({commit}, payload) {
      commit('setMyPromoPageLoading', true)
      try {
        const response = await api.promoPage.getPromoPageContactId(payload.event_id, payload.contact_id, payload.acceptLanguage);
        commit('setMyPromoPage', response);
        commit('setMyPromoPageLoading', false)
      } catch (error) {
        commit('setMyPromoPage', null);
        commit('promoPageError', error.data);
        commit('setMyPromoPageLoading', false)
      }
    },
    updatePromoPage: async function ({commit}, payload) {
      commit('setEditPromoPageLoading', true);
      commit('setEditPromoPageSuccess', false);

      try {
        const response = await api.promoPage.updatePromoPage(payload, payload.event_id, payload.external_id);
        commit('setEditPromoPageSuccess', true);
        if (response && response.statusText === 'Accepted') {
          commit('setEditPromoPageSuccess', true);
        }
        // commit('setPromoPageList', resolve);
      } catch (error) {
        commit('setEditPromoPageSuccess', false);
        commit('promoPageError', error.data);
      } finally {
        commit('setEditPromoPageLoading', false);
      }
    },
    putUserPromoPage: async function ({commit}, payload) {
      try {
        return await api.promoPage.putUserPromoPage(payload);
      } catch (e) {
        /* do nothing */ // Bypassing linter.
      }
      return null;
    },
    deleteUserPromoPage: async function ({commit}, payload) {
      try {
        return await api.promoPage.deleteUserPromoPage(payload.event_id, payload.external_id, payload.user_id);
      } catch (e) {
        /* do nothing */ // Bypassing linter.
      }
      return null;
    },
    putFileUrlPromoPage: async function ({commit}, payload) {
      try {
        await api.promoPage.putFileUrlPromoPage(payload.data, payload.event_id, payload.external_id)
        // commit('setPromoPageList', response);
      } catch (error) {
        commit('promoPageError', error.data);
      }
    },
    deleteFileUrlPromoPage: async function ({commit}, payload) {
      try {
        await api.promoPage.deleteFileUrlPromoPage(payload.file_id, payload.event_id, payload.external_id);
        // commit('setPromoPageList', response);
      } catch (error) {
        commit('promoPageError', error.data);
      }
    },
    putPhotoVideoUrlPromoPage: async function ({commit}, payload) {
      try {
        await api.promoPage.putPhotoVideoUrlPromoPage(payload.data, payload.event_id, payload.external_id);
        // commit('setPromoPageList', response);
      } catch (error) {
        commit('promoPageError', error.data);
      }
    },
    deletePhotoVideoUrlPromoPage: async function ({commit}, payload) {
      try {
        await api.promoPage.deletePhotoVideoUrlPromoPage(payload.data, payload.event_id, payload.external_id)
        // commit('setPromoPageList', response);
      } catch (error) {
        commit('promoPageError', error.data);
      }
    },
    setContactListType: async function ({commit}, payload) {
      commit('setContactListType', payload)
    },
    setCompaniesListType: async function ({commit}, payload) {
      commit('setCompaniesListType', payload)
    },
    setNavigationTab: async function ({commit}, payload) {
      commit('_setNavigationTab', payload)
    },
    putPromoPageProduct: async function ({commit}, payload) {
      try {
        return await api.promoPage.putPromoPageProduct(payload.event_id, payload.external_id, payload.data);
      } catch (e) {
        commit('promoPageError', e.data);
      }
      return null;
    },
    patchPromoPageProduct: async function ({commit}, payload) {
      try {
        return await api.promoPage.patchPromoPageProduct(payload.event_id, payload.external_id, payload.product_id, payload.data);
      } catch (e) {
        commit('promoPageError', e.data);
      }
      return null;
    },
    deletePromoPageProduct: async function ({commit}, payload) {
      try {
        return await api.promoPage.deletePromoPageProduct(payload.event_id, payload.external_id, payload.product_id);
      } catch (e) {
        commit('promoPageError', e.data);
      }
      return null;
    },
    getPavilion: async function ({ commit }, payload) {
      try {
        const response = await api.promoPage.getPavilion(payload);
        commit('setPavilion', response);
      } catch (error) {
        commit('promoPageError', error.data);
      }
    },
    companiesFoundOnRequest: function ({ commit }, payload) {
      commit('companiesFoundOnRequest',payload)
    },
    setEditPromoPageSuccess: function ({ commit }, value) { // TS value: boolean
      commit('setEditPromoPageSuccess', value);
    }
  },
}

